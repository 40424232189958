<template>
  <b-container fluid>
      <b-alert v-if="erro" show variant="danger">{{descErro}}</b-alert>
      <b-row class="larguraminima">
        <b-col cols="1" class="filtro pt-4"> 
          <!-- <b-jumbotron> -->
          <b-form-datepicker class="mt-2"
            v-model="selectedData"
            :min="minData"
            :max="maxData"
            placeholder="Escolha uma data"
            label-help
            label-no-date-selected="Nenhuma data selecionada"
            locale="pt-BR"
          ></b-form-datepicker>
          <b-button variant="primary" class="mt-1"
            @click="add()"
          >Adicionar data ao gráfico</b-button>          
          <!-- <b-alert class="mt-1" show variant="warning">Escolha uma data para comparar com hoje antes de gerar o relatório.</b-alert> -->
          <hr/>
          <Filtros @setFiltro="setFiltro"/>
          <hr/>
          <b-form-group label="Gráfico por modalidade:" class="mt-1" >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="exibirPorTipos"
              :options="tipoGrafico"
              buttons
              button-variant="outline-primary"
              name="radio-btn-outline"
            ></b-form-radio-group>
          </b-form-group>
          <hr/>
          <div class="d-flex justify-content-center">
            <b-button variant="success" @click="gerarRelatorio">Visualizar clientes</b-button>
          </div>
          <hr/>
          <b-alert class="mt-3" show>Atualização automática em {{atualizarSecs}} segundos.</b-alert>
          <!-- </b-jumbotron> -->
        </b-col>
        <template v-if="!exibirPorTipos">
          <b-col>
              <grafico class="mt-1" key="gg" ref="gg" id="gg" titulo="Total" :datas="datas" :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" :tipo="null"/>
          </b-col>         
        </template>
        <template v-else>
          <b-col cols="9">
            <div class="flex">
                <grafico key="ggLocal" id="ggLocal" titulo="LOCAL" :datas="datas" :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" tipo="LOCAL" />
                <grafico key="ggLdn" id="ggLdn" titulo="LDN" :datas="datas" :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" tipo="LDN,POP"/>
                <grafico key="ggLdi" id="ggLdi" titulo="LDI" :datas="datas"  :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" tipo="LDI"/>
                <grafico key="ggVc1" id="ggVc1" titulo="VC1" :datas="datas" :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" tipo="VC1"/>
                <grafico key="ggVc2" id="ggVc2" titulo="VC2" :datas="datas" :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" tipo="VC2,VC2POP"/>
                <grafico key="ggVc3" id="ggVc3" titulo="VC3" :datas="datas" :urlFiltro="url" :atualizarTemporizador="atualizarTemporizador" tipo="VC3,VC3POP"/> 
             </div>
          </b-col>
        </template>
        
      </b-row>
  </b-container>
</template>

<script>
import grafico from "./grafico.vue"
import Filtros from "./components/Filtros.vue"

export default {
  name: "App",
  components: { grafico, Filtros },
  data() {
    return {
      erro:false,
      descErro:'Ocorreu algum erro ao montar o gráfico: ',
      atualizarSecs: 120,
      controlTimer:null,
      atualizarTemporizador:0,//todo arrumar isso
      datas:[],      
      linhas: [],
      linhasLocal: [],
      url:'',
      selectedData: "",
      datasDisp: [],
      dataAtual: "",
      minData: "",
      maxData: "",
      exibirPorTipos: false,
      tipoGrafico: [
          { text: 'Não', value: false },
          { text: 'Sim', value: true },
        ],
      dataRelat:null
    };
  },
  methods: {
    setFiltro(c) {
            console.log("setFiltro" ,c)
            this.url=c
    },
    add(){
      if (this.selectedData) {
        console.log('data seelecionada', this.selectedData);
        
        this.datas.push({data:this.formatdata(this.selectedData),cor:'#'+Math.floor(Math.random()*16777215).toString(16)})
      }
    },
    formatdata: function(data) {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0,4)}`;
    },
    /*handlerOkRelat: async function(bvModalEvt) {
      bvModalEvt.preventDefault()
      console.log('data', this.dataRelat);
      if (this.dataRelat) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-data-compar-relat-online')
          this.gerarRelatorio()
        })
      }
    },*/
    gerarRelatorio: async function() {
      //mudar depois esse endereco
      console.log("relatorio, data ref select ", this.dataRelat);
        let url =
          "/#/relat-online-por-contrato?" 
          + this.url
        window.open(url, "_blank")
    },
    async carregaDatasDisponiveis() {
      let r = await this.$http.get("/minutos/online/datas");
      console.log("datas disp", r.data[0])
      this.minData = `${r.data[0].MINDATA.substring(6,10)}-${r.data[0].MINDATA.substring(3, 5)}-${r.data[0].MINDATA.substring(0,2)}`
      this.maxData = `${r.data[0].MAXDATA.substring(6,10)}-${r.data[0].MAXDATA.substring(3, 5)}-${r.data[0].MAXDATA.substring(0,2)}`
    },
    getUrlFiltro(){
      let contratos = [];
      let url = "&empresas=" + this.filtro.selectedEmp.toString()
      url += this.filtro.codvend ? "&" + this.filtro.cargo + "=" + this.filtro.codvend : ""
      Object.values(this.filtro.clientes).forEach(c => contratos.push(c.AS_CONTRAT))
      url += contratos.length > 0 ? "&contratos=" + contratos.toString() : ""
      return url
    },
    showModalClientes() {
      console.log("clique showModalClientes")
      this.$bvModal.show("modal-filtro-clientes")
    },
  },
  computed:{
   
  },
  deactivated(){
    console.log('deactivaded');
  },
  destroyed(){
    console.log('destroyed');
    clearInterval(this.controlTimer)
  },
  async mounted() {
    try{
      await this.carregaDatasDisponiveis()
      //await this.carregaListaEmpresas() //espero carregar as empresas para q as proximas buscas utilizem o filtro das empresas ja preenchido totalmente
      let d = await this.$http.get("minutos/online/data-atual");
      this.dataAtual = d.data[0].DATA;
      let d1 = new Date(
        this.dataAtual.substring(6, 10),
        this.dataAtual.substring(3, 5) - 1,
        this.dataAtual.substring(0, 2)
      );
      let d7 = new Date(
        this.dataAtual.substring(6, 10),
        this.dataAtual.substring(3, 5) - 1,
        this.dataAtual.substring(0, 2)
      );
      // let d1 = new Date(2020 , 3-1, 1)
      // console.log('d1', d1)
      d1.setDate(d1.getDate() - 1);
      d7.setDate(d7.getDate() - 7);
      // this.add(this.dataAtual, "red");
      // this.add(d1.toLocaleString().substring(0, 10), "blue");
      // this.add(d7.toLocaleString().substring(0, 10), "cyan");
      console.log('d1d4', d1.toLocaleString('pt-br').substring(0, 10), d7.toLocaleString('pt-br').substring(0, 10));
      
      this.datas.push({data:this.dataAtual,cor:'red'})
      this.datas.push({data:d1.toLocaleString('pt-br').substring(0, 10),cor:'blue'})
      this.datas.push({data:d7.toLocaleString('pt-br').substring(0, 10),cor:"cyan"})
      
      let seft=this
      this.controlTimer = setInterval(() => {
        seft.atualizarSecs--
        // console.log('atualizarSecs', seft.atualizarSecs)
        if (seft.atualizarSecs==-1){
          console.log('atualizar temporizador',seft.atualizarTemporizador)
          seft.atualizarTemporizador++
          seft.atualizarSecs=120
        }
      }, 1000)

      console.log('terminou Mounted da App', this.datas);
    }catch(err){
        this.erro=true
        this.descErro+= err.response ? err.response.data :  err
        console.log('Erro carregando iniciando página do gráfico:', err); //todo ver sobre o tratamento correto de mensagens de erro do axios
    }
  },
};
</script>

<style>
.flex{
  display:flex;
  flex-wrap: wrap;
}
#ggLocal, #ggLdn, #ggLdi, #ggVc1, #ggVc2, #ggVc3 {
  /* width: 700px; */
  /* height: 9000px; */
  max-width:500px;
  position: relative;
}
hr.style2 {
  border-top: 3px double #8c8b8b;
}
#jum {
  padding: 10px;
  margin: 10px;
}
.menu{
  padding: 10px;
  margin: 10px;
  width:100px;
  height:2000px;
}
.filtro{
  /* margin-top:10px; */
  min-width: 25vh;
  max-width: 50vh;
  background-color: #e9ecef;
}
/* abaixo desse valor o menu desaparece
@media (max-width: 130px) {
    .filtro {
        display: none;
    }
} */
.larguraminima{
  /* min-width: 800px; */
  min-width: 120vh;
}

.modal-data-relat{
 justify-content:flex-start!important;
}
</style>
