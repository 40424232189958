<template>
  <div>
    <Relatorio :items="items" :fields="fields" primaryKey="AS_CONTRAT">
        
        <p v-if="loading && !erro">
            <b-spinner></b-spinner>Atualizando...
        </p>
        <p class="mb-1" v-else> 
          Total de contratos: {{ rows }}. 
          <span v-if="this.$route.query.datacomparar">Data de comparação: 
            <strong>
              {{this.$route.query.datacomparar}}.
            </strong>
          </span>
          <b-link v-b-modal.modal-1>Clique aqui para saber mais sobre as colunas amarelas.</b-link>
        </p> 

        <b-alert v-if="erro" show variant="danger">{{descErro}}</b-alert>

          <b-modal id="modal-1" title="Ligações não completados / descartadas" ok-only>
            <p class="my-4">NC: Quantidade de ligações não completadas. Elas possuem duração igual a 0 segundos.</p>
            <p class="my-4">Descarte: Quantidade de ligações abaixo da cadência contratada. Se a cadência mínima for 4s por exemplo, esse é o total de ligações com 1, 2 ou 3 segundos.</p>
            <p class="my-4">Produtividade: Porcentagem das ligações que foram cobrados do cliente, considerando o total de ligações NC + DESCARTE.</p>
            <p class="my-4">Chamadas curtas (%): Porcentagem das ligações com duração inferior a 4s. Incluindo NC, descarte e ligações cobradas. Dado disponível a partir de 24/11/22, anterior a essa data será apresentado "-1". </p>
            <p class="my-4">Chamadas Completadas 1s até 3s: quantidade de ligações completadas/tarifadas entre 1s e 3s. Esse valor também é utilizado na coluna 'Quant. Ligações'. Dado disponível a partir de 24/11/22, anterior a essa data será apresentado "-1". </p>
            <p class="my-4">O valor -1 tambem será usado quando não for possível quantificar a quantidade de cdrs NC. Isso poderá ocorrer para clientes pequenos com menos de 100 ligações por dia.</p>
          </b-modal>

        <template v-slot:COMPARA_COM_MEDIA={celula}> 
          <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>    
        </template>

         <template v-slot:DIFMIN={celula}> 
          <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>    
        </template>

        <template v-slot:DIFMINDATACOMPARAR={celula}> 
          <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>    
        </template>

        <template v-slot:DIFPORDATACOMPARAR={celula}> 
          <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>    
        </template>

        <!-- <template v-slot:top-TOTALMIN> 
          1234
        </template> -->
        
    </Relatorio>
  </div>
</template>

<script>
import Relatorio from "./components/RelatorioBase";
import * as util from "./util"
export default {
  components: { Relatorio },
  data() {
    return {
      // totais:[
      //   {key:'TOTALMIN', valor:50000},
      //   {key:'TOTALMOVEL', valor:599},
      // ],
      erro:false,
      descErro:'Ocorreu algum erro ao montar o relatório. Tire um print dessa página e entre em contato com seu suporte de TI. ',
      loading:false,
      // totais:{},
      // totais1:[],
      items: [],
      fields: [
        { key: "AS_CONTRAT", label: "Contrato", sortable: true },
        { key: "AS_NOME", label: "Cliente", sortable: true,class:"tamanho-minimo" },// todo tentar passar direto o tamanho ao inves dessa classe com valor fixo
        { key: "AS_NOME_FIGLIST", label: "Nome Fantasia", sortable: true,class:"tamanho-minimo" },
        { key: "DESC_PRODUTO", label: "Produto", sortable: true,class:"tamanho-minimo" },        
        {
          key: "TOTALMIN",
          label: "Minutos (total)",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'TOTALMIN'),
        },
        {
          key: "MEDIAMIN",
          label: "Média (minutos)",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'MEDIAMIN'),
        },
        {
          key: "DIFMIN",
          label: "Diferença (minutos)",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'DIFMIN'),
          aplicarCorTopRow:true
        },
        {
          key: "COMPARA_COM_MEDIA",
          label: "Total X Media(%)",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>((util.totalizaColuna(items,'TOTALMIN')/util.totalizaColuna(items,'MEDIAMIN'))-1)*100,
          aplicarCorTopRow:true
        },
        ...(() => this.retornaCamposComparar())(),       
        {
          key: "LIGACOES",
          label: "Quant. ligações",
          sortable: true,
          formatter: 'formatarDecimaisPara0',
          calculaTopRow:(items)=>util.totalizaColuna(items,'LIGACOES'),
        },
        {
          key: "MEDIALIG",
          label: "Média (ligações)",
          sortable: true,
          formatter: "formatarDecimaisPara0",
          calculaTopRow:(items)=>util.totalizaColuna(items,'MEDIALIG'),
        }, 
        {
          key: "NC",
          label: "NC (ligações)",
          sortable: true,
          formatter: "formatarDecimaisPara0",
          variant: 'warning',
          calculaTopRow:(items)=>util.totalizaColuna(items,'NC'),
        },        
        {
          key: "DESCARTE_CADENCIA",
          label: "Descarte (ligações)",
          sortable: true,
          formatter: "formatarDecimaisPara0",
          variant: 'warning',
          calculaTopRow:(items)=>util.totalizaColuna(items,'DESCARTE_CADENCIA'),
        },
        {
          key: "PRODUTIVIDADE",
          label: "Produtividade (%)",
          sortable: true,
          formatter: "formatarDecimais",
          variant: 'warning',
          calculaTopRow:(items)=>(util.totalizaColuna(items,'LIGACOES')/(util.totalizaColuna(items,'NC')+util.totalizaColuna(items,'DESCARTE_CADENCIA')+util.totalizaColuna(items,'LIGACOES'))*100),
        },
        {
          key: "QUANT_LIG_123S",
          label: "Chamadas Completadas 1s até 3s",
          sortable: true,
          formatter: "formatarDecimaisPara0",
          variant: 'warning',
          calculaTopRow:(items)=>util.totalizaColuna(items,'QUANT_LIG_123S'),
        },
        {
          key: "CHAMADASCURTAS",
          label: "Chamadas Curtas (%)",
          sortable: true,
          formatter: "formatarDecimais",
          variant: 'warning',
          calculaTopRow:(items)=>(
            (
              util.totalizaColuna(items,'QUANT_LIG_123S')+
              util.totalizaColuna(items,'NC')
            )/
            (
              util.totalizaColuna(items,'NC')+
              util.totalizaColuna(items,'DESCARTE_CADENCIA')+
              util.totalizaColuna(items,'LIGACOES')
            )*
            100
          ),
        },
        {
          key: "TOTALMOVEL",
          label: "Móvel",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'TOTALMOVEL'),
        },
        {
          key: "TOTALFIXO",
          label: "Fixo",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'TOTALFIXO'),
        },
        {
          key: "LOCAL",
          label: "LOCAL",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'LOCAL'),
        },
        {
          key: "LDN",
          label: "LDN",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'LDN'),
        },
        {
          key: "VC1",
          label: "VC1",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'VC1'),
        },
        {
          key: "VC2",
          label: "VC2",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'VC2'),
        },
        {
          key: "VC3",
          label: "VC3",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'VC3'),
        },
        {
          key: "LDI",
          label: "LDI",
          sortable: true,
          formatter: "formatarDecimais",
          calculaTopRow:(items)=>util.totalizaColuna(items,'LDI'),
        },        
        { key: "ULTIMALIGACAO", label: "Última Ligação", sortable: true },
        { key: "CIDADE", label: "Cidade", sortable: true },
        { key: "ESTADO", label: "Estado", sortable: true },
        { key: "ASSESSOR", label: "Assessor", sortable: true, class:"tamanho-minimo" },
        { key: "SUPERVISOR", label: "Supervisor", sortable: true ,class:"tamanho-minimo"},
        { key: "GERENTE", label: "Gerente", sortable: true,class:"tamanho-minimo" },
        { key: "GERENTE_REGIONAL", label: "Gerente Regional", sortable: true ,class:"tamanho-minimo"},
        { key: "SUPER_INTENDENTE", label: "Superintendente", sortable: true ,class:"tamanho-minimo"},
        { key: "DIRETOR", label: "Diretor", sortable: true,class:"tamanho-minimo" },
      ],
      url: this.$urlApi + "relat",
    };
  },
  methods: {
    retornaCor(value){
      return util.retornaCor(value)
    },
    retornaCamposComparar(){
      let campos=[]
        if (this.$route.query.datacomparar) {
          campos=[
                    {
                      key: "TOTALMINCOMPARAR",
                      label: "Minutos Data Comparação",
                      sortable: true,
                      formatter: "formatarDecimais",
                      variant: 'info',
                      calculaTopRow:(items)=>util.totalizaColuna(items,'TOTALMINCOMPARAR'),
                    },
                    {
                      key: "DIFMINDATACOMPARAR",
                      label: "Diferença (minutos)",
                      sortable: true,
                      formatter: "formatarDecimais",
                      variant: 'info',
                      calculaTopRow:(items)=>util.totalizaColuna(items,'DIFMINDATACOMPARAR'),
                      aplicarCorTopRow:true
                    },
                    {
                      key: "DIFPORDATACOMPARAR",
                      label: "Total_X_DataComparação (%)",
                      sortable: true,
                      formatter: "formatarDecimais",
                      variant: 'info',
                      calculaTopRow:(items)=>((util.totalizaColuna(items,'TOTALMIN')/util.totalizaColuna(items,'TOTALMINCOMPARAR'))-1)*100,
                      aplicarCorTopRow:true
                    }
          ]
        }
        return campos
    },
      // totais:[
      //   {key:'TOTALMIN', valor:50000},
      //   {key:'TOTALMOVEL', valor:599},
      // ],
    /*calculaTotais(campos){
      let totais={}
      campos.forEach(campo=>{
        let total=this.items.reduce((acumulador, linha)=>{
          // console.log('reduce',linha[campo] || 0);
          return acumulador + Number(linha[campo] || 0)
        },0)
        totais[campo]= total
      })
      return totais
    },*/
    /*soma(colunaParaSomar){
        let total=this.items.reduce((acumulador, linha)=>{
          return acumulador + Number(linha[colunaParaSomar] || 0)
        },0)
      return total
    },*/    
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  async mounted() {
    try{
      this.loading=true
      console.log("query", this.$route.query);
      let position = this.$route.fullPath.indexOf("?");
      let url = this.$route.fullPath.substr(position);
      let r = await this.$http.get("minutos/diario/relat-porcontrato" + url);
      this.items = r.data;
      // console.log('exemplo linha', r.data[0]);
      /*this.totais= this.calculaTotais(['DESCARTE_CADENCIA','DIFMINDATACOMPARAR','DIFMIN','MEDIALIG','TOTALMIN','TOTALMOVEL','TOTALMINCOMPARAR','MEDIAMIN','TOTALFIXO','LOCAL','LDN','LDI','VC1','VC2','VC3','LIGACOES'])
      this.totais.COMPARA_COM_MEDIA=((this.totais.TOTALMIN/this.totais.MEDIAMIN)-1)*100
      this.totais.DIFPORDATACOMPARAR=((this.totais.TOTALMIN/this.totais.TOTALMINCOMPARAR)-1)*100
      this.totais.PRODUTIVIDADE=(this.totais.LIGACOES/(this.totais.NC+this.totais.DESCARTE_CADENCIA+this.totais.LIGACOES))*100
      this.totais1=[
        {
          key:"NC",
          funcao:(items)=>this.util.totalizaColuna(items,'NC'),
          formatter:'formatarDecimaisPara0'
        },
        {
          key:"COMPARA_COM_MEDIA",
          funcao:(items)=>((this.util.totalizaColuna(items,'TOTALMIN')/this.util.totalizaColuna(items,'MEDIAMIN'))-1)*100,
          formatter:'formatarDecimais'
        },
        {
          key:"DIFPORDATACOMPARAR",
          funcao:(items)=>((this.util.totalizaColuna(items,'TOTALMIN')/this.util.totalizaColuna(items,'TOTALMINCOMPARAR'))-1)*100,
          formatter:'formatarDecimais'
        },
        {
          key:"PRODUTIVIDADE",
          funcao:(items)=>(this.util.totalizaColuna(items,'LIGACOES')/(this.util.totalizaColuna(items,'NC')+this.util.totalizaColuna(items,'DESCARTE_CADENCIA')+this.util.totalizaColuna(items,'LIGACOES'))*100),
          formatter:'formatarDecimais'
        },
      ]*/
      this.loading=false
    }catch(err){
        this.erro=true
        this.descErro+= err.response ? err.response.data :  err
        console.log('Erro carregando relatório:', err); //todo ver sobre o tratamento correto de mensagens de erro do axios
    }
  },
};
</script>

<style>
.tamanho-minimo{
    min-width:200px;
}
</style>
