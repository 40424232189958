<template>
  <div>
    <Relatorio :items="items" :fields="fields">
        <p class="mb-1"> Total de clientes: {{ rows }}</p> 
        <b-alert v-if="erro" show variant="danger">{{descErro}}</b-alert>
        <p v-if="loading && !erro">
          <b-spinner></b-spinner>
          <b-alert
            :show="dismissCountDown"
            variant="warning"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            Buscando clientes zerados. Favor aguardar. Não atualize a página. Tempo máximo para gerar o relatório: {{ dismissCountDown }} segundos.
          </b-alert>
        </p>
        <!-- <template v-slot:COMPARA_COM_MEDIA={celula}> 
                <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>    
        </template> -->
    </Relatorio>
  </div>
</template>

<script>
import Relatorio from "./components/RelatorioBase";
import * as util from "./util"
export default {
  components: { Relatorio },
  data() {
    return {
      erro:false,
      descErro:'Ocorreu algum erro ao gerar o relatório. Revise os parâmetros selecionados ou entre em contato com o sap@. Detalhe do erro: ',
      dismissCountDown: 300,
      loading:true,
      items: [],
      fields: [],
       
      url: this.$urlApi + "relat",
    };
  },
  methods: {
     countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
    retornaCor(valor) {
      // console.log('valor', valor,Number(valor)<0);
      if (valor < 0) {
        return "red";
      } else {
        return "blue";
      }
    },
    retornaDias(){
      const retornaDiaDaSemana = (data)=> {
        const diasDaSemana = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado"]
          return diasDaSemana[data.getDay()]
      }
      
      let colunas=[]
      if (this.items.length>0){
          Object.entries(this.items[0]).forEach(coluna => {
            if (coluna[0].includes('DIA_')){
              let dia = coluna[0].substr(4,7)
              let mes = this.$route.query.mesano.substr(0,2)
              let ano = this.$route.query.mesano.substr(2,6)
              let data= new Date(ano,mes-1, dia)
              // console.log('data', ano,mes-1, dia,data,data.getDay());
              
              colunas.push({ key: coluna[0],
                            label: coluna[0] + ' '+ retornaDiaDaSemana(data),
                            sortable: true,
                            formatter: "formatarDecimais",
                            calculaTopRow:(items)=>util.totalizaColuna(items,coluna[0]),
                          })
            }
          })


        /*{   key: "DIA_01",
            label: "DIA_01 Segunda",
            sortable: true,
            formatter: "formatarDecimais",
         }*/
      }
      return colunas
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
    
  },
  async mounted() {
    try{
      console.log("query", this.$route.query);
      let position = this.$route.fullPath.indexOf("?");
      let url = this.$route.fullPath.substr(position);
      let r = await this.$http.get("minutos/diario/relat-zerados-porcontrato" + url,{timeout:this.dismissCountDown*1000})
      this.items = r.data;
      // console.log('relat', r.data);
      
      this.fields=[
        { key: "AS_CONTRAT", label: "Contrato", sortable: true },
          { key: "AS_NOME", label: "Cliente", sortable: true,class:"tamanho-minimo" },// todo tentar passar direto o tamanho ao inves dessa classe com valor fixo
          { key: "AS_NOME_FIGLIST", label: "Nome Fantasia", sortable: true,class:"tamanho-minimo" },
          { key: "DESC_PRODUTO", label: "Produto", sortable: true,class:"tamanho-minimo" },
          { key: "ASSESSOR", label: "Assessor", sortable: true, class:"tamanho-minimo" },
          { key: "QUANT_DIAS_ZERADOS", label: "Quant. Dias Zerados", sortable: true},
          {
            key: "DATA_MAIOR_MINUTOS",
            label: "Data Maior Minutagem",
            sortable: true,
          },
          {
            key: "MAX_TOTALMIN",
            label: "Maior Minutagem",
            sortable: true,
            formatter: "formatarDecimais",
          },
          ...(() => this.retornaDias() )(),           
          { key: "CIDADE", label: "Cidade", sortable: true },
          { key: "ESTADO", label: "Estado", sortable: true },
          { key: "SUPERVISOR", label: "Supervisor", sortable: true },
          { key: "GERENTE", label: "Gerente", sortable: true },
          { key: "GERENTE_REGIONAL", label: "Gerente Regional", sortable: true },
          { key: "SUPER_INTENDENTE", label: "Superintendente", sortable: true },
          { key: "DIRETOR", label: "Diretor", sortable: true },
        
        ]
    }catch(err){
      console.log('Erro relat zerados:', err); //todo ver sobre o tratamento correto de mensagens de erro do axios
        this.erro=true
        this.descErro+= err.response ? err.response.data :  err
     }

      this.loading=false
  },
};
</script>

<style>

.tamanho-minimo{
    min-width:200px;
}
</style>
