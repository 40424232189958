import Vue from 'vue'
import App from './App.vue'
import Diario from './Diario.vue'
import Zerado from './Zerado.vue'
import Periodo from './Periodo.vue'
import RelatOnlinePorContrato from './RelatOnlinePorContrato.vue'
import RelatDiarioPorContrato from './RelatDiarioPorContrato.vue'
import RelatZeradoPorContrato from './RelatZeradoPorContrato.vue'
import RelatPeriodoPorData from './RelatPeriodoPorData.vue'
import Inicial from './Inicial.vue'
import Dash from './Dash.vue'
import Login from './Login'
import Home from './Home'
import Sair from './Sair'
// import Menu from './Menu.vue'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import App from './RandomChart.vue'
// import VueCharts from 'vue-chartjs'
import Axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter  from 'vue-router'

console.log('var de ambiente', process.env);

// Axios.defaults.baseURL = "http://140.1.180.180:3077/"
Axios.defaults.baseURL = process.env.VUE_APP_URLAPIMINUTOS
Axios.defaults.withCredentials = true
Vue.prototype.$http = Axios;

Axios.interceptors.response.use(
  function (response) {
    return response
}, async function (error) {
    console.log('axios resposta com erro', error)
    if (error.response && error.response.status === 401) {
      // console.log('path',router.path);
      await Axios.get('login/sair') 
      router.replace({ path: '/login/sair' })
    }
    return Promise.reject(error)
})

// Vue.prototype.$urlApi="http://allanlopes1:3077/"
// Vue.prototype.$urlApi="http://140.1.180.180:3077/"

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.use(VueRouter)

/*
const router = new VueRouter({
  routes: [
    { path: '/relat',
      name: 'relat',
      component: Relat,
    },
    { path: '/app',
      name: 'App',
      component: App,
    },
  ]
})*/

/*new Vue({
  router,
  render: h => h(Menu),
}).$mount('#app')*/

// const NotFound = { template: '<p>Página não encontrada </p>' }
// const Home = { template: '<p>Página Inicial</p>' }
// const About = { template: '<p>Sobre</p>' }

// const routes = {
//   '/': App,
//   '/relat': Relat,
//   '/nao':Nao
// }

// new Vue({
//   el: '#app',
//   data: {
//     currentRoute: window.location.pathname
//   },
//   computed: {
//     ViewComponent () {
//       console.log(this.currentRoute);
      
//       return routes[this.currentRoute] || Nao
//     }
//   },
//   render (h) { return h(this.ViewComponent) }
// })



const router = new VueRouter({
  routes: [
    {
      path: '/dash',
      // component: Dash, //se nao usar o routeview name="a" acontece aquele bug de aparecer 2x a barra de navegacao
      component: Dash, //se nao usar o routeview name="a" acontece aquele bug de aparecer 2x a barra de navegacao
      meta: { requiresAuth: true},
      children: [        
        {
          path: '/app',
          component: App,
          meta: { requiresAuth: true}        
        }, {
          path: '/minutagem',
          component:  Inicial ,
          meta: { requiresAuth: true}
        }, {
          path: '/diario',
          component:  Diario ,
          meta: { requiresAuth: true}
        }, {
          path: '/zerado',
          component:  Zerado ,
          meta: { requiresAuth: true}
        }, {
          path: '/periodo',
          component:  Periodo ,
          meta: { requiresAuth: true}
        },
      ]
    },
    {
      path: '/',
      component: Login ,
    },
    {
      path: '/sair',
      component:  Sair ,
    },
    {
      path: '/relat-online-por-contrato/',
      component: RelatOnlinePorContrato,
      meta: { requiresAuth: true},
      // props: (route) => (
      //   { 
      //     datacompar: route.query.datacompar,
      //     empresas: route.query.datacompar,
      //     contratos: route.query.datacompar,

      //   }) 
    },
    {
      path: '/relat-diario-por-contrato/',
      component: RelatDiarioPorContrato,
      props: true, 
      meta: { requiresAuth: true},
    },
    {
      path: '/relat-zerado-por-contrato/',
      component: RelatZeradoPorContrato,
      props: true, 
      meta: { requiresAuth: true},
    },
    {
      path: '/relat-periodo-por-data/',
      component: RelatPeriodoPorData,
      props: true, 
      meta: { requiresAuth: true},
    },
    {
      path: '*',
      component: Login,
    }
    
  ]
})
// { path: '/search', component: SearchUser, props: (route) => ({ query: route.query.q }) }

router.beforeResolve((to, from, next) => {
  console.log('beforeResolve',to);
  // next()
  
  try{
    if (to.meta.requiresAuth){//talvez tirar isso
      if (document.cookie.includes('sessionidmin')) {
        if (to.fullPath=='/'){
          next('/dash')
        }else{
          next()
        }
      }else {
        // next('/')
        next({ path: '/', query:{redirect: to.fullPath  }});
      }
    }else{
      if (document.cookie.includes('sessionidmin') && to.fullPath=='/') {
          next('/dash')
      } else {
        next()
      }
    }
  }catch(err){
    console.log('erro na beforeResolve', err)
    next(err)
  }
})

router.onError(error => {
  console.log('erro de rota', error);
});

new Vue({
  el:'#app',
  router: router,
  render: h => h(Home)
})

let viewheight = window.innerHeight;
let viewwidth = window.innerWidth;
console.log('size', viewheight,viewwidth);

let viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");

// .$mount('#app')


// console.log((!!{}.teste));
