<template>
    <b-container fluid>
    <p> Seja bem-vinda(o) {{nomeFunc}}.</p>
    <hr/>
    <p> Suas credenciais de acesso são: </p>
    <template v-if="hierarquia">
        <p >Código: {{hierarquia.codVend}} </p>
        <p >Nome: {{hierarquia.nomVend}} </p>
        <p >Cargo: {{hierarquia.descCargo}} </p>
    </template>
    <template v-else>
         <p>Você tem acesso completo a todos os clientes.</p>
    </template>
    </b-container>
</template>
<script>
    export default {
        data(){
            return{
                nomeFunc:'',
                hierarquia:null
            }
        },
        methods:{
            async getFunc(){
                let r = await this.$http.get('/login/meusdados')
                console.log(r.data);
                this.hierarquia = r.data.hierarquia
                this.nomeFunc=r.data.nomeUsuario

            }
        },       
        mounted(){
            this.getFunc()
        }

    }
</script>
