<template>
  <!-- <b-container fluid sm> -->
    <b-jumbotron class="filtro">
     <b-form-select v-model="mesAnoSelected" :options="mesAnoLista"></b-form-select>
      <b-alert v-if="alertDataObrigatoria" show variant="danger">
        Escolha um mês antes de gerar o relatório.
      </b-alert>
      <hr />
      <Filtros @setFiltro="setFiltro" />
      <hr />
      <div class="d-flex justify-content-center">
        <b-button class="opcoesfiltro" variant="success" @click="relatorio"
          >Gerar relatório</b-button
        >
      </div>
    </b-jumbotron>
  <!-- </b-container> -->
</template>

<script>
import Filtros from "./components/Filtros.vue";

export default {
  name: "App",
  components: { Filtros },
  data() {
    return {
      mesAnoLista: [
          { value: null, text: 'Escolha um mês' },
      ],
      url: "",
      mesAnoSelected: null,
      alertDataObrigatoria:false
    };
  },
  methods: {
    setFiltro(c) {
      console.log("setFiltro", c);
      this.url = c;
    },
    formatdata: function(data) {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}` //todo colocar uma regra para nao quebrar template string - prettier e vetur
    },
    relatorio: async function() {
      //mudar depois esse endereco
      console.log("relatorio, data ref select ", this.selectedData);
      if (this.mesAnoSelected) {
        this.alertDataObrigatoria=false
        // this.$router.push({ path: '/relat/'})
        let url =
          "/#/relat-zerado-por-contrato/?mesano=" +
          this.mesAnoSelected +
          this.url;
        console.log(url);

        window.open(url, "_blank");
      }else{
        this.alertDataObrigatoria=true
      }
    },
    showModalClientes() {
      console.log("clique showModalClientes");
      this.$bvModal.show("modal-filtro-clientes");
    },
  },
  async mounted() {
    const dataAtual = new Date()    
    for (let ano = dataAtual.getFullYear();ano>=dataAtual.getFullYear()-3;ano--){
      for (let mes=1;mes<=12; mes++){
        // console.log(String(mes).padStart(2,'0')+ano);
        this.mesAnoLista.push(
          { value: String(mes).padStart(2,'0')+ano, text: String(mes).padStart(2,'0')+ '/'+ano}
        )
      }
    }
  },  
};
</script>

<style scoped>
/* todo melhorar esse css q esta duplicado entre as paginas */
.filtro {
  width: 50%;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  align-content: center;
  min-width: 25vh;
  max-width: 50vh;
  margin-right: 0;
}

@media (max-width: 600px) {
  .filtro {
      width: 100%;
      padding: 5px;
  }
  hr{
    margin:4px;
  }
} 
</style>
