<template>
  <div>
    <b-alert v-if="erro" show variant="danger">{{descErro}}</b-alert>
        <template v-if="!tipo">
          <b-row>
            <b-col cols="8">
            <!-- <b-col> -->
              <line-chart ref="linechart"
                :linhas="linhas"
                :titulo="titulo" class="tamanhografico"/>
            </b-col>
            <b-col>
              <b-table-lite 
                  id="tabela-por-hora"
                  :items="tabelaPorHora"
                  small
                  striped
                />
                  <!-- :fields="fields" -->
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <line-chart ref="linechart"
                :linhas="linhas"
                :titulo="titulo"/>
        </template>
        
  </div>
</template>

<script>
// import { Line, mixins } from "vue-chartjs";
import LineChart from './components/LineChartOnline.vue'

// const { reactiveData } = mixins;

export default {
  // name: "grafico",
  // extends: Line,
  components: { LineChart },
  // mixins: [reactiveData],
  props: {
    datas: Array,
    titulo: String,
    // filtro: Object,
    urlFiltro: String,
    atualizarTemporizador: Number,
    tipo: String
  },
  data() {
    return {
      erro:false,
      descErro:'Ocorreu algum erro ao montar o gráfico: ',
      datasLocal:[],
      linhas:[],
       chartDatateste:{
        labels: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22,
          23
        ],
        /*datasets: {
          label: 'label',
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderColor: 'red',
          data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
          pointStyle: true
        }*/
      },
    };
  },
  methods: {
    formatdata: function(data) {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0,4)}`
    },
    async add(data, cor) {
      this.erro=false
      try{
        // 2012-05-02
        // console.log("add data", data);
        let url = "/minutos/online/porhora?data=" + data + this.urlFiltro
        if (this.tipo) url+= "&tp_lig=" + this.tipo
        // console.log("url linha grafico", url);
        // let r = await this.$http.get(url,{data: {}});//passar objeto data deu outro erro de cors
        let r = await this.$http.get(url);
        // console.log('linhas', r.data)
        let { linha, label } = this.tratarLinha(data, r.data);
        let index = this.existeLinha(data); 
        if (index != -1) {
          this.atualizarLinha(index, linha, label);
        } else {
          this.inserirLinha(linha, label, cor);
        }
      }catch(err){
        this.erro=true
        this.descErro+= err.response ? err.response.data :  err
        console.log('Erro carregando linhas do gráfico:', err); //todo ver sobre o tratamento correto de mensagens de erro do axios
      }
    },
    inserirLinha(linha, label, cor) {
      this.linhas.push({
        label: label,
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: cor,
        data: linha,
        pointStyle: true
      });
    },
    atualizarLinha(index, linha, label) {
      // console.log("atualizando linha atual", label);
      for (let i = 0; i <= 23; i++) {
        this.linhas[index].data[i] = linha[i];
      }
      this.linhas[index].label = label;
      this.refreshGrafico() //como o watcher nao monita atualizacao do array, apenas o push. tenho q forca o refresh dele aqui. usar o deepwatcher da confiltro com o wacther do chartData.
    },
    existeLinha(data) {
      let index = -1;
      this.linhas.forEach((l, i) => {
        if (l.label.substring(0, 10) == data) index = i;
      });
      return index;
    },
    tratarLinha(data, novaLinha) {
      let total = 0;
      let linha = [];
      for (let i = 0; i <= 23; i++) {
        total += novaLinha[i].DURACAO;
        linha.push(novaLinha[i].DURACAO);
      }
      let label = data + ": " +  total.toLocaleString("pt-BR", { maximumFractionDigits: 2 })
      return { linha, label }
    },
    async atualizarLinhas() {
      this.linhas.forEach(l => this.add(l.label.substring(0, 10)))
    },
    refreshGrafico() {
      // console.log("update chart titulo", this.titulo);
      this.$refs.linechart.refreshGrafico()
      // this.$data._chart.update();
    },
    adicionaDatasGrafico(){
      // console.log('datas watch', this.datas)
      // console.log('datasLocal', this.datasLocal)
      this.datas.forEach(d=>{
        if (!this.datasLocal.includes(d.data)){
          this.add(d.data,d.cor)
          this.datasLocal.push(d.data)
        }
      })
    },
  },
  computed:{
    tabelaPorHora(){
      let tab=[]
      for (let i=0;i<=23;i++){
        tab.push({hora:i})
      }
      this.linhas.forEach(l=>{
        let nomeColuna= l.label.substring(0,10)
        l.data.forEach((v,idx)=>{
          tab[idx][nomeColuna]=v.toLocaleString("pt-BR", { maximumFractionDigits: 2 })
        })
      })
      return tab
    }
  },
  mounted() {
    console.log("montando grafico titulo", this.titulo)
    this.adicionaDatasGrafico()
    //essa var charData é criada pelo proprio componente de grafico
  },
  watch: {
    urlFiltro:{
      handler: function (val,old){
        console.log('filtro watch', val, old)
        this.datasLocal.forEach(d=>this.add(d)) //acho q a cor devera vir do app
      },
      deep: true,
    } ,
    atualizarTemporizador(){
      this.datasLocal.forEach(d=>this.add(d)) 
    },
    datas(){
     this.adicionaDatasGrafico()
    },
    // linhas: function() {
      // console.log("linhas alteradas", "titulo", this.titulo)
      // this.$data._chart.update()
      /*this.chartData={
          labels: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
          datasets: this.linhas
        }*/
    // },
    // titulo: function(o,n){
    //   console.log("titulo alterado", "titulo", this.titulo, 'o / n', o, n)
    //   this.$data._chart.update();
    // },

    /*watch: {
        chartData () {
          this.$data._chart.update()
        }
      }*/
  }
};
</script>

<style scoped>
#tabela-por-hora {
  font-size: 14px;
}

.tamanhografico{
  /* margin-top:10px; */
  min-width: 70vh;
  /* max-width: 50vh; */
  /* background-color: #e9ecef; */
}
</style>