<template>
   <div class="corpologin">
      <b-container fluid class="divlogin"> 
          <form class="login" @submit.prevent="validar" >
            <h1 class="mt-5">Portal Minutos</h1>
            <b-input class="mt-5" required :disabled="isdisabled" v-model="login" type="text" placeholder="Usuário de rede"/>
            <b-input class="mt-1" required :disabled="isdisabled" v-model="senha" type="password" placeholder="Senha de rede"/>
            <hr/>
            <b-button class="mt-5 btn-block" type="submit" :disabled="isdisabled">Login</b-button>
            <b-alert class="mt-5" v-if="erro" show variant="danger">{{erroDetalhe}}</b-alert>
          </form>
    </b-container>
 </div>

</template>

<script>
import Bowser from "bowser"
export default {
  data() {
    return {
      login:'',
        senha:'',
        erro:false,
        erroDetalhe:'',
        navegador:'',
        isdisabled:false
      }
  },
  methods: {
    validar: async function () {
      try{
        this.erro=false
        await this.$http.post('login',{login:this.login,senha:this.senha, navegador:this.navegador} )
        console.log('login ok', this.$route.query.redirect);
        // this.$router.replace({ path: this.$route.query.redirect || '/dash' })
        if (this.$route.query.redirect){
          this.$router.replace({ path: '/dash', query:{redirect: this.$route.query.redirect }});
        }else{
          this.$router.replace({ path: '/dash'});
        }
        
      }catch(err){
        console.log('err login', err);
        this.erro=true
        if (err.response) {
          this.erroDetalhe=err.response.data
        }else{
          this.erroDetalhe='Ocorreu algum erro ao tentar validar seu usuário e senha. Tente novamente. Se o erro persistir informe o sap@.'
        }
      }
    },
    toLocaleStringSupportsLocales() {
      try {
        new Date().toLocaleString('i');
      } catch (e) {
        return e instanceof RangeError;
      }
      return false;
    },
  },
  mounted(){
    let browser = Bowser.getParser(window.navigator.userAgent)
    let navegadorCompativel=true
    console.log('browser',browser);
    this.navegador = JSON.stringify(browser.getBrowser())
    this.navegador+= ' - plataforma ' + browser.getPlatform().type
    if (browser.isBrowser('Internet Explorer')) {
      this.erro=true
      this.erroDetalhe="Sistema não compatível com o Internet Explorer. Utilize outro navegador."
      this.isdisabled=true
      return
    }

    if (browser.getPlatform().type=='desktop'){
        navegadorCompativel = browser.satisfies({
          chrome: '>30.0.4044.138',  
          "edge":">12",
          "firefox":">30",
          opera:">15",
          safari:">10"
        })
    }

    if (!this.toLocaleStringSupportsLocales || !navegadorCompativel){
      this.erro=true
      this.erroDetalhe="Seu navegador não é compatível. O sistema não irá funcionar corretamente. Tente um mais atualizado."
      this.isdisabled=true
    }

    if (process.env.VUE_APP_LOGINTESTE){
      this.login=process.env.VUE_APP_LOGINTESTE
      this.senha=process.env.VUE_APP_SENHATESTE
    }
  }
}
</script>

<style scoped>

.corpologin{
    /* background:  #7386D5; */
    background-image: url('./../public/bg-01.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
}

.divlogin {
    background: rgba(255, 255, 255, 0.438);
    text-align: center;
    vertical-align: middle;
    align-content: center;
    width: 400px;
    height: 500px;
    margin: auto ;
}

@media (max-width: 700px)  {
    .divlogin {
       width:100%;
       height: 100%;
    }
}

@media  (max-height: 375px) {
    .divlogin {
       width:100%;
       height: 100%;
    }
    h1{
      display: none;
    }
    
    /* #sidebar.active {
        margin-left: 0;
    } */
}

</style>