<template>
  <b-container fluid class="m-1">
      <b-row class="align-items-center">
        <b-col md="4">
          <!-- <span>Data de comparação:</span> -->
          <b-form-group
            label="Data de comparação:"
            label-for="calendario">
            <b-form-datepicker class="mt-2 tamanho"
              id="calendario"
              v-model="dataCompar"
              :min="minData"
              :max="maxData"
              placeholder="Escolha uma data"
              label-help
              label-no-date-selected="Nenhuma data selecionada"
              locale="pt-BR"              
            />            
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="Ligações até:"
            label-for="select-horario">
            <b-form-select  id="select-horario" v-model="hora" :options="listaHorarios"></b-form-select> 
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-alert v-if="!erro" id="alert123" show>Atualização automática em {{atualizarSecs}} segundos.</b-alert>
          <b-alert v-if="erro" show variant="danger">{{descErro}}</b-alert>
        </b-col>
        <b-col>
          <p v-if="loading && !erro">
            <b-spinner></b-spinner>Atualizando...
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Relatorio class="p-0 " primaryKey="AS_CONTRAT" :items="items" :fields="fields" :itemsPorPagina=15 :quantContratosDataAtual="quantContratoDataAtual" :quantContratosDataRef="quantContratoDataComparacao">
            <template v-slot:DIFPORC="{celula}">
              <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>
            </template>
            <template v-slot:DIFMIN="{celula}">
              <span :class="retornaCor(celula.unformatted)">{{celula.value}}</span>
            </template>
          </Relatorio>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import Relatorio from "./components/RelatorioBase";
import * as util from "./util"
export default {
  components: { Relatorio },
  data() {
    return {
      erro:false,
      descErro:'Ocorreu algum erro ao montar o relatório. Tire um print dessa página e entre em contato com seu suporte de TI.',
      loading:false,
      atualizarSecs: 120,
      controlTimer:null,
      minData: "",
      maxData: "",
      dataCompar:null,
      items: [],
      quantContratoDataAtual:0,
      quantContratoDataComparacao:0,
      listaHorarios:[
          { value: '00', text: '00:59' },
          { value: '01', text: '01:59' },
          { value: '02', text: '02:59' },
          { value: '03', text: '03:59' },
          { value: '04', text: '04:59' },
          { value: '05', text: '05:59' },
          { value: '06', text: '06:59' },
          { value: '07', text: '07:59' },
          { value: '08', text: '08:59' },
          { value: '09', text: '09:59' },
          { value: '10', text: '10:59' },
          { value: '11', text: '11:59' },
          { value: '12', text: '12:59' },
          { value: '13', text: '13:59' },
          { value: '14', text: '14:59' },
          { value: '15', text: '15:59' },
          { value: '16', text: '16:59' },
          { value: '17', text: '17:59' },
          { value: '18', text: '18:59' },
          { value: '19', text: '19:59' },
          { value: '20', text: '20:59' },
          { value: '21', text: '21:59' },
          { value: '22', text: '22:59' },
          { value: '23', text: '23:59' },
      ],
      fields: [
        { key: "AS_CONTRAT", label: "Contrato", sortable: true },
        { key: "AS_NOME", label: "Cliente", sortable: true },
        { key: "AS_NOME_FIGLIST", label: "Nome Fantasia", sortable: true },
        { key: "ASSESSOR", label: "Assessor", sortable: true },
        { key: "PRODUTO", label: "Produto", sortable: true },
        {
          key: "TOTALMINUTOS",
          label: "Data Atual",
          formatter: "formatarDecimais",
          sortable: true,
          class:"tamanho-minimo-quant-contrato",
          calculaTopRow:(items)=>util.totalizaColuna(items,'TOTALMINUTOS'),
        },
        {
          key: "TOTMINREF",
          label: "Data Comparação",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'TOTMINREF'),
        },
        {
          key: "DIFMIN",
          label: "Diferença (minutos)",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'DIFMIN'),
          aplicarCorTopRow:true
        },
        {
          key: "DIFPORC",
          label: "Diferença (%)",
          formatter: "formatarDecimais",
          sortable: true,          
          calculaTopRow:(items)=>((util.totalizaColuna(items,'TOTALMINUTOS')/util.totalizaColuna(items,'TOTMINREF'))-1)*100,
          aplicarCorTopRow:true
        },
        {
          key: "LOCAL",
          label: "Local",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'LOCAL'),
        },
        {
          key: "LDN",
          label: "LDN",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'LDN'),
        },
        {
          key: "LDI",
          label: "LDI",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'LDI'),
        },
        {
          key: "VC1",
          label: "VC1",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'VC1'),
        },
        {
          key: "VC2",
          label: "VC2",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'VC2'),
        },
        {
          key: "VC3",
          label: "VC3",
          formatter: "formatarDecimais",
          sortable: true,
          calculaTopRow:(items)=>util.totalizaColuna(items,'VC3'),
        }
      ],
      url:'',
      hora: ""
    };
  },
  methods: {
    retornaCor(value){
      return util.retornaCor(value)
    },
    async carregaDatasDisponiveis() {
      //todo esse metodo esta duplicado em App.vue. 
      let r = await this.$http.get("/minutos/online/datas");
      console.log("datas disp", r.data[0])
      this.minData = `${r.data[0].MINDATA.substring(6,10)}-${r.data[0].MINDATA.substring(3, 5)}-${r.data[0].MINDATA.substring(0,2)}`
      this.maxData = `${r.data[0].MAXDATA.substring(6,10)}-${r.data[0].MAXDATA.substring(3, 5)}-${r.data[0].MAXDATA.substring(0,2)}`
    },
    async atualizaRelat(){
      console.log('atualizaRelat  data ehora', this.dataCompar,this.hora);
      try{
        if (this.hora && this.dataCompar ){
          this.loading=true
          let r = await this.$http.get(
          "minutos/online/relat-porcontrato"
            + this.url 
            + "&datacompar=" + `${this.dataCompar.substring(8, 10)}/${this.dataCompar.substring(5, 7)}/${this.dataCompar.substring(0, 4)}` //todo colocar uma regra para nao quebrar template string - prettier e vetur
            + "&hora=" +  this.hora
          );
          console.log('r', r);
          this.items = r.data;
          this.quantContratoDataAtual = this.items.filter(i=>i.TOTALMINUTOS>0).length
          this.quantContratoDataComparacao = this.items.filter(i=>i.TOTMINREF>0).length
          this.loading=false
        }
      }catch(err){
        this.erro=true
        this.descErro+= err.response ? err.response.data :  err
        console.log('Erro carregando relatório:', err); //todo ver sobre o tratamento correto de mensagens de erro do axios
        clearInterval(this.controlTimer)
      }
    },
    formatdata: function(data) { //todo melhorar essas formatacoes de data
      // return `${("0" + data.getDate()).slice(-2)}/${("0" + (data.getMonth() + 1)).slice(-2)}/${data.getFullYear()}`;
      return data.getFullYear() + '-'+("0" + (data.getMonth() + 1)).slice(-2) +'-'+ ("0" + data.getDate()).slice(-2)
    },
    criaTemporizador(){ //todo duplicado
      let seft=this
      this.controlTimer = setInterval(() => {
        seft.atualizarSecs--
        // console.log('atualizarSecs', seft.atualizarSecs)
        if (seft.atualizarSecs==-1){
          console.log('atualizar temporizador',seft.atualizarTemporizador)
          seft.atualizaRelat()
          seft.atualizarSecs=120
        }
      }, 1000)
    }
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  watch:{
    dataCompar(){
      this.atualizaRelat()
    },
    hora(){
      this.atualizaRelat()
    }
  },
  async mounted() {
    console.log("query", this.$route.query);
    this.carregaDatasDisponiveis()
    let dataCompar = new Date()
    dataCompar.setDate(dataCompar.getDate() - 7)
    this.dataCompar = this.formatdata(dataCompar)
    let position = this.$route.fullPath.indexOf("?")
    this.url = this.$route.fullPath.substr(position)
    let resultHora = await this.$http.get("minutos/online/max-hora-atual/");
    this.hora = resultHora.data[0].HORA
    console.log("hora", this.hora);
    // this.atualizaRelat() O Watch da data e hora que executa a api do relatorio
    this.criaTemporizador()
    // console.log('a',r.items.filter(i=>i.TOTALMINUTOS>0))
  },
  destroyed(){
    console.log('destroyed');
    clearInterval(this.controlTimer)
  },
};
</script>

<style>
.tamanho-minimo-quant-contrato{
    min-width: 100px;
}

</style>