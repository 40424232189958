<template>
    <b-container fluid>
        <b-row class="mt-2" >
            <b-col>
                <slot></slot>
            </b-col>
        </b-row>
        <b-row class="mb-1 justify-content-start">
            <b-col md="4" class="">
                <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Filtre por qualquer informação aqui."
                    size="sm"
                    class="larguraminima1 larguramaxima1"
                ></b-form-input>
            </b-col>      
            <!-- <b-col class="col-md-auto"> -->
            <b-col md="1">
                <b-button @click="exportCsv" size="sm" class="">Exportar</b-button>
            </b-col>   
            <b-col md="3">
                <label class="mr-1" for="perPageSelect">Por página: </label>
                 <!-- <b-form-group
                    id="input-group-1"
                    label="Por página:"
                    label-for="perPageSelect"
                    label-align="left"
                > -->
                    <b-form-select
                        v-model="perPage"
                        id="perPageSelect"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                <!-- </b-form-group> -->
             </b-col>
        </b-row>
        <b-table
            id="relat-table-base"
            :items="items"
            :fields="fields"
            :filter="filter"
            :primary-key="primaryKey"
            :per-page="perPage"
            :current-page="currentPage"
            small
            striped hover
            sort-icon-left
            responsive="sm"
            bordered
            @filtered="onFiltered"
            :sort-compare="sortCompare"
            :tbody-tr-class="rowClass"
        >
            <!-- sticky-header="800px" -->
            <!-- :no-border-collapse="true" -->

            <!-- isso funciona. nao cagar mais. só preciso agora montar um loop de todos os campos assim ele ficarao disponiveis pra serem utilizados-->
            <!-- <template v-slot:cell(PRODUTO)> 
                <slot name="PRODUTO"></slot>
            </template> -->
                
                <!-- 'cell(' + field key + ')'. -->

            <template v-for="slot of Object.keys(this.$scopedSlots)" v-slot:[`cell(${slot})`]="data">
                <slot :name="slot" :celula="data"/>
                <!-- <slot name=slot>{{slot}}</slot> -->
                <!-- {{data.value}}tt -->
            </template>

            <!-- testar se nao tiver objeto totais -->
            
            <!-- <template v-slot:top-row="data" v-if="totais"> 
                <b-td v-for="idx in data.fields" :key="idx.key">
                    <span key="totalgeral" v-if="idx.key=='AS_CONTRAT'">
                        Totais:
                    </span>
                    <template v-for="(valor,key) in totais">
                        <span :key="key" v-if="idx.key==key">
                            <p class="mb-0">{{formatarDecimais(valor)}}</p>
                        </span>
                    </template>
                    <span v-if="quantContratosDataAtual && quantContratosDataRef">
                        <p v-if="idx.key=='TOTALMINUTOS'" class="mb-0">{{quantContratosDataAtual}} contratos</p>
                        <p v-if="idx.key=='TOTMINREF'" class="mb-0">{{quantContratosDataRef}} contratos</p>
                    </span>
                </b-td>
            </template> -->


        <!-- solucao abaixo usando funcao como parametro -->
            <template v-slot:top-row> 
                <b-td v-for="(field,idx) in fields" :key="field.key" :setVar="campoTopRow = criaColunaTopRow(field.key)">
                    <p v-if="idx===0" class="mb-0">Totais</p>
                    <p v-else :class="'mb-0 ' + campoTopRow.cor">{{campoTopRow.valorFormatado}}</p>
                    <!-- <span key="totalgeral" v-if="idx.key=='AS_CONTRAT'">
                        Totais:
                    </span> -->
                    <!-- <template v-for="(coluna,key) in fields">
                        <span :key="key" v-if="idx.key==coluna.key && coluna.calculaTopRow" :set="item = getUserData(id)">
                            <template v-if="filter">

                            </template>
                            <p v-if="filter" class="mb-0 red">{{exec(coluna.formatter,(coluna.calculaTopRow(filteredItems)))}}</p>
                            <p v-else class="mb-0 blue">{{exec(coluna.formatter,(coluna.calculaTopRow(items)))}}</p>
                        
                        </span>
                    </template>-->
                    <span v-if="quantContratosDataAtual && quantContratosDataRef">
                        <p v-if="field.key=='TOTALMINUTOS'" class="mb-0">{{quantContratosDataAtual}} contratos</p>
                        <p v-if="field.key=='TOTMINREF'" class="mb-0">{{quantContratosDataRef}} contratos</p>
                    </span> 
                </b-td>
            </template> 

            <!-- isso funciona como slot -->
            <!-- <template v-slot:top-row="data"> 
                <b-td v-for="idx in data.fields" :key="idx.key">
                    <slot :name="'top-' + idx.key"></slot>
                </b-td>
            </template> -->
            
                        
            <!-- <template v-slot:top-row="data"> 
                <b-td v-for="idx in data.fields" :key="idx.key">
                    <span>
                        teste
                    </span>                    
                </b-td>
            </template> -->

             <!-- <template v-slot:top-row="data">
                    <b-td v-for="idx in data.fields" :key="idx.name">
                        <span v-if="idx.key=='TOTALMIN'">total aqui</span>
                    </b-td>
            </template> -->

            <!-- <template v-for="field in fields"> -->
                <!-- <template v-if="$scopedSlots[field.key]"> -->
                    <!-- <template v-slot:cell(field.key)> isso funciona. nao cagar mais. só preciso agora montar um loop de todos os campos assim ele ficarao disponiveis pra serem utilizados -->
                        <!-- <slot :name="field.key"></slot> -->
                    <!-- </template> -->
                <!-- </template> -->
            <!-- </template> -->
        
         <!-- <template v-for="(_, slot) of $scopedSlots" v-slot:cell(slot)="data">              
             <slot :name="slot"> {{data.value}}  aa</slot>
          </template>  -->

        <!-- <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">              
             <slot :name="slot" v-bind="scope"/
          </template> -->

          <!-- <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">              
             <slot :name="slot" v-bind="scope"/>
          </template> -->
            <!-- <template v-slot:cell(DIFMIN)="data">
                <span :class="retornaCor(data.unformatted)">{{data.value}}</span>    
            </template>

            <template v-slot:cell(DIFPORC)="data">
                <span :class="retornaCor(data.unformatted)">{{data.value}}</span>    
            </template> -->

        </b-table>

        <b-pagination
            id="paginacao"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="relat-table"
        ></b-pagination>
    </b-container>
</template>

<script>
import {retornaCor} from "./../util"
export default {
    data() {
      return {
            perPage: 10,
            currentPage: 1,
            totalRows:0,
            filter:null,
            pageOptions: [5, 10, 15,20,30,50,75,100,300],
            filteredItems:[],
      }
    },
    props:{
        items:Array,
        fields:Array,
        primaryKey:String,
        // totais:Object,
        // totais1:Array,
        itemsPorPagina:Number,
        quantContratosDataAtual:Number,
        quantContratosDataRef:Number
    },
    methods:{
        criaColunaTopRow(nomeCampo){
            const coluna = this.fields.filter(field=> field.key === nomeCampo)
            if (!coluna[0].calculaTopRow) return ''
            const valor =  coluna[0].calculaTopRow(this.filter ? this.filteredItems : this.items)            
            const valorFormatado = this.exec(coluna[0].formatter,valor)
            let cor = null
            if (coluna[0].aplicarCorTopRow){
                cor = retornaCor(valor)
            }
            return {valorFormatado,cor}
        },
        rowClass(item, type) {   
            if (type === 'row-top') return "font-weight-bold"
            // if (type === 'row-top') return "font-weight-bold"
            return ''
        },
        exec(func,param){
            return this[func](param)
        },
        exportCsv(){
            let csvHeader = "data:text/csv;charset=utf-8,%EF%BB%BF" //codigo para utf8
            let csvContent=''
            let self = this
            console.log('self.fields',self.fields);
            
            self.fields.forEach(f=>csvContent+=f.label+';')
            csvContent += "\r\n"
            const linhasParaExportar = this.filter ? this.filteredItems : this.items
            linhasParaExportar.forEach(function(o) {
                let row=''
                self.fields.forEach(f=>{
                    if (f.formatter){
                        row += self.formatarDecimais(o[f.key]) + ';'    
                    }else{
                        row += o[f.key] + ';'
                    }
                })
                csvContent += row + "\r\n"
            })
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", csvHeader+encodedUri);
            link.setAttribute("download", "relat_minut.csv");
            document.body.appendChild(link); // Required for FF
            link.click(); // 
        },
        formatarDecimais(value) {
            //  return value.toFixed(2)
            if (!value) return 0
            return Number(value).toLocaleString('pt-BR', { maximumFractionDigits: 2 , minimumFractionDigits: 2})
       },
       formatarDecimaisPara0(value) {
            //  return value.toFixed(2)
            if (!value) return 0
            return Number(value).toLocaleString('pt-BR', { maximumFractionDigits: 0 , minimumFractionDigits: 0})
       },
       onFiltered(filteredItems, quantLinhas) {
           console.log('quantLinhas', quantLinhas)
           this.filteredItems = filteredItems
        // Trigger pagination to update the number of buttons/pages due to filtering
        // this.totalRows = filteredItems.length
        this.totalRows = quantLinhas
        this.currentPage = 1
      },
       sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions ,compareLocale) { 
            const a = aRow[key] 
            const b = bRow[key]
            if ((typeof a === 'number' && typeof b === 'number') || (a instanceof Date && b instanceof Date)) {
                // If both compared fields are native numbers or both are native dates
                // console.log('native number');
                
                return a < b ? -1 : a > b ? 1 : 0
            }else if (this.isData(a) && this.isData(b)){
                // console.log('entrou data');
                const data1= this.converteDeTextoDDMMYYYYParaDate(a)
                const data2= this.converteDeTextoDDMMYYYYParaDate(b)
                return data1 < data2 ? -1 : data1 > data2 ? 1 : 0
            } else {
                // console.log('else');
                // Otherwise stringify the field data and use String.prototype.localeCompare
                // if (!a) return -1
                // if (!b) return -1
                return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions)
            }
            // console.log(a.localeCompare(b, 'en', { sensitivity: 'base' }));
        },
        toString(value) {
            if (value === null || typeof value === 'undefined') {
                return ''
            } else if (value instanceof Object) {
                return Object.keys(value)
                .sort()
                .map(key => toString(value[key]))
                .join(' ')
            } else {
                return String(value)
            }
        },
        isData(texto){
            try{
                const barra1=texto.substr(2,1)
                const barra2=texto.substr(2,1)
                const tamanho = texto.length
                if (barra1==='/' && barra2==='/' && tamanho===10) return true
                return false
            }catch(err){
                return false
            }
        },
        converteDeTextoDDMMYYYYParaDate(str) {
            // console.log(a.substring(6,10),a.substring(3,5),a.substring(0,2) )
            const data= new Date(str.substring(6,10),str.substring(3,5)-1,str.substring(0,2) )
            return data
        }
    },
    computed: {
      rows() {
        return this.items.length
      },
    },
    watch: {
       items(){
            this.totalRows=this.items.length
            console.log('total linhas',this.totalRows );
       },
    },
    mounted(){
       /*this.totais1.forEach(coluna=>{
           console.log('12',coluna.funcao())
       })*/
      /* console.log('lista de slots recebidos',this.$scopedSlots)
       for (let slot of Object.keys(this.$scopedSlots)){
             console.log('slot',slot)
        }*/
        if (this.itemsPorPagina) this.perPage=this.itemsPorPagina
        
    //     this.totalRows=this.items.length
    //    console.log('total linhas',this.totalRows );
    }

}
</script>

<style scoped>
.table-responsive-sm, .table{
    /* font-size: 14px; */
    font-size: 0.8em;
}
.larguraminima{
    min-width: 200px;
}
.larguramaxima{
    max-width: 300px;
}
#perPageSelect{
    width: 80px;
}

</style>