<template>
  <!-- <b-container fluid sm> -->
    <b-jumbotron class="filtro">
      <b-form-datepicker
        v-model="selectedDataIni"
        placeholder="Escolha uma data inicial."
        label-help
        label-no-date-selected="Nenhuma data selecionada"
        locale="pt-BR"
      />
      <b-form-datepicker
        v-model="selectedDataFim"
        placeholder="Escolha uma data final."
        label-help
        label-no-date-selected="Nenhuma data selecionada"
        locale="pt-BR"
      />
      <b-alert v-if="alertDataObrigatoria" show variant="danger">
        Escolha o período antes de gerar o relatório.
      </b-alert>
      <hr />
      <Filtros @setFiltro="setFiltro" />
      <hr />
      <b-form-checkbox
          id="checkbox"
          v-model="agrupaAssessor"
          name="checkbox-1"
        >
          Totalizar por assessor?
      </b-form-checkbox>
      <hr />
      <div class="d-flex justify-content-center">
        <b-button class="opcoesfiltro" variant="success" @click="relatorio"
          >Gerar relatório</b-button
        >
      </div>
    </b-jumbotron>
  <!-- </b-container> -->
</template>

<script>
import Filtros from "./components/Filtros.vue";

export default {
  name: "App",
  components: { Filtros },
  data() {
    return {
      url: "",
      selectedDataIni: "",
      selectedDataFim: "",
      comparar:false,
      alertDataObrigatoria:false,
      agrupaAssessor:false
    };
  },
  methods: {
    setFiltro(c) {
      console.log("setFiltro", c);
      this.url = c;
    },
    formatdata: function(data) {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}` //todo colocar uma regra para nao quebrar template string - prettier e vetur
    },
    formataDataParaCalendario: function(data) {
      return data.getFullYear() + '-'+("0" + (data.getMonth() + 1)).slice(-2) +'-'+ ("0" + data.getDate()).slice(-2)
    },
    relatorio: async function() {
      //mudar depois esse endereco
      if (this.selectedDataIni && this.selectedDataFim) {
        this.alertDataObrigatoria=false
        let url =
          `/#/relat-periodo-por-data/?dataini=${this.formatdata(this.selectedDataIni)}&datafim=${this.formatdata(this.selectedDataFim)}&${this.url}`
        if (this.agrupaAssessor) url+='&agrupaassessor=true'
        console.log('url',url);
        window.open(url, "_blank");
      }else{
        this.alertDataObrigatoria=true
      }
    },
  },
  async mounted() {
    const dataMenos1 = new Date()
    dataMenos1.setDate(dataMenos1.getDate()-1)
    const firstDay = new Date(dataMenos1.getFullYear(), dataMenos1.getMonth(), 1);
    const lastDay = new Date(dataMenos1.getFullYear(), dataMenos1.getMonth() + 1, 0);

    this.selectedDataFim= this.formataDataParaCalendario(lastDay)
    this.selectedDataIni= this.formataDataParaCalendario(firstDay)
  },
  
};
</script>

<style scoped>

.filtro {
  width: 50%;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  align-content: center;
  min-width: 25vh;
  max-width: 50vh;
  margin-right: 0;
}

@media (max-width: 600px) {
  .filtro {
      width: 100%;
      padding: 5px;
  }
  hr{
    margin:4px;
  }
} 

</style>
