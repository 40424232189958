<template>
  <!-- <b-container fluid> -->
    <b-jumbotron class="filtro">
      <b-form-datepicker
        v-model="selectedData"
        placeholder="Escolha uma data"
        label-help
        label-no-date-selected="Nenhuma data selecionada"
        locale="pt-BR"
      />
      <b-alert v-if="alertDataObrigatoria" show variant="danger">
        Escolha uma data antes de gerar o relatório.
      </b-alert >
      <hr />      
      <Filtros @setFiltro="setFiltro" />
      <hr />
      <b-form-checkbox
          id="checkbox"
          v-model="comparar"
          name="checkbox-1"
        >
          Adicionar data para comparação?
      </b-form-checkbox>
      <b-form-datepicker v-if="comparar"        
        v-model="selectedDataComparar"
        placeholder="Escolha uma data para comparar"
        label-help
        label-no-date-selected="Nenhuma data selecionada"
        locale="pt-BR"
      />
      <hr />
      <div class="d-flex justify-content-center">
        <b-button class="text-center" variant="success" @click="relatorio"
          >Gerar relatório</b-button
        >
      </div>
    </b-jumbotron>
  <!-- </b-container> -->
</template>

<script>
import Filtros from "./components/Filtros.vue";

export default {
  name: "App",
  components: { Filtros },
  data() {
    return {
      url: "",
      selectedData: "",
      selectedDataComparar: "",
      comparar:false,
      alertDataObrigatoria:false
    };
  },
  methods: {
    setFiltro(c) {
      console.log("setFiltro", c);
      this.url = c;
    },
    formatdata: function(data) {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}` //todo colocar uma regra para nao quebrar template string - prettier e vetur
    },
    relatorio: async function() {
      //mudar depois esse endereco
      console.log("relatorio, data ref select ", this.selectedData);
      if (this.selectedData) {
        // this.$router.push({ path: '/relat/'})
        let dataComparar = this.selectedDataComparar && this.comparar ? '&datacomparar=' + this.formatdata(this.selectedDataComparar) : ''
        let url =
          "/#/relat-diario-por-contrato/?data=" +
          this.formatdata(this.selectedData) +
          this.url + dataComparar
        console.log('url',url);

        window.open(url, "_blank");
      }else{
        this.alertDataObrigatoria=true
      }
    },
    showModalClientes() {
      console.log("clique showModalClientes");
      this.$bvModal.show("modal-filtro-clientes");
    },
  },
  async mounted() {
    console.log("terminou Mounted da Diario"); //todo melhor isso e todo o tratamento feito com datas
    let dataMenos1 = new Date()
    dataMenos1.setDate(dataMenos1.getDate()-1)
    let dataStrFormatoCalendar=dataMenos1.getFullYear() + '-'+("0" + (dataMenos1.getMonth() + 1)).slice(-2) +'-'+ ("0" + dataMenos1.getDate()).slice(-2)
    this.selectedData= dataStrFormatoCalendar
    

  },
  watch: {
    selectedData(data) {
      console.log("selectedData", this.selectedData);
      if(data) this.alertDataObrigatoria=false
    },
  },
};
</script>

<style scoped>

.filtro {
  width: 50%;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  align-content: center;
  min-width: 25vh;
  max-width: 50vh;
  margin-right: 0;
}

@media (max-width: 600px) {
  .filtro {
      width: 100%;
      padding: 5px;
  }
  hr{
    margin:4px;
  }
} 
</style>

