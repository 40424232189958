<template>
    <div>
        <b-form-select
            v-model="cargoSelect"
            :options="cargosLista"
            size="sm"
        />
            <!-- :inputClass="'formatainput'" -->
        <vue-bootstrap-typeahead
            id="autocomplete-assessor"
            idInput="inputa78"
            size="lg"
            v-model="search"
            :data="itens"
            :serializer="s => s.COD_VEND + ' - ' + s.NOM_VEND" 
            placeholder="Busque pelo nome ou código."
            @hit="selected = $event"       
            :valorInicial="valorInicial ? valorInicial : ''"
        />
    </div>
</template>

<script>
export default {
    name:'Assessor',
    data() {
      return {
        search:'',
        itens:[],
        selected:{},
        input:'',
        url: "assessores/",
        cargosLista: [
            { value: "cod_vend", text: "Assessor" },
            { value: "cod_sup", text: "Supervisor" },
            { value: "cod_ger", text: "Gerente" },
            { value: "cod_reg", text: "Gerente Regional" },
            { value: "cod_super", text: "Superintendente" },
            { value: "cod_dir", text: "Diretor" }
        ],
        cargoSelect:'cod_vend'
      }
    },
    props:{
        valorInicial:String,
        label:String

    },
    methods:{
        teste(a){
            console.log('teste input', a);
            
        },
        async getItens(addr){
            let r = await this.$http.get(this.url+'?filter='+String(addr).toLowerCase()) //retirar essa variavel q
            console.log('a', r);
            this.itens = r.data
        },        
    },
    watch: {
        // addressSearch: _.debounce(function(addr) { this.getAddresses(addr) }, 500) //todo fazer o debounce
        selected:function(obj) { 
            console.log('selecionado id', obj);
            if (Object.values(obj).length!=0) obj.cargoSelect=this.cargoSelect
            this.$emit("hit",obj)
            this.input.style.border='' 
            this.input.style.color=''
            
        },
        search: function(search) { 
           /* console.log('search', this.search)
            console.log('search', search)
            console.log('selected', this.selected)
            console.log('selected length',Object.keys(this.selected).length)*/

            this.getItens(search) 
                // this.inputModelo.style.border='1px red solid'
                // this.inputModelo.style.color='red'
            
            if (search != '' && Object.keys(this.selected).length==0){
                console.log('vermelho')
                this.input.style.border='1px red solid'
                this.input.style.color='red'
                // this.inputHolding.classList.add('falha')
            }
            
            if (search == '' || search == null){
            //    this.selectedHolding = null
            //    this.inputHolding.classList.remove('falha')
                this.input.style.border=''
                this.input.style.color=''
            }
            
           /* if ( this.selectedHolding != null){
            //    this.inputHolding.classList.remove('falha')
                this.inputHolding.style.border=''
                this.inputHolding.style.color=''
            }*/
        },
        cargoSelect:function() {
            console.log('cargoSelect',this.selected, Object.keys(this.selected).length)
            if (Object.keys(this.selected).length) {
                this.selected.cargoSelect=this.cargoSelect
                this.$emit("hit",this.selected)
            }
        }
        
    },
    mounted(){
        // this.getHoldings(70989)
        console.log('montando objeto assessor, valor inicial', this.valorInicial);
        this.input=document.getElementById('inputa78')
        // this.inputHolding.setAttribute("id", "inputholding");
        /*this.inputHolding.style.fontSize = '1rem'
        this.inputHolding.style.height = '38px'
        this.inputHolding.style.padding='6px 12px 6px 12px'*/
        // this.inputHolding.value ="teste inneasdfasd"
    }
       /* 'filtro.DBA_DS': function(filtro) { 
            console.log('filtro', filtro);
             filtro.DBA_DS = this.$options.filters.uppercase(filtro);
        }*/

}
</script>

<style scoped>

/* style="font-size: 1rem;" coloquei essa merda direto no input //todo rever isso */
/* #inputa78{
    font-size: 1rem ; 
    height : 38px;
    padding: 6px 12px 6px 12px
} 
.formatainput{
    font-size: 1rem !important;
    height : 38px;
    padding: 6px 12px 6px 12px
} 

/* .falha{
    border:1px red solid;
    color: red;
} */
</style>