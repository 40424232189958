<template>
    <div>
        <b-container fluid >
           <b-input required v-model="input" type="text" placeholder="Busque por número de contrato, nome, cpf ou cnpj."/>
           <p>
                <b-button size="sm" @click="selectAllRows">Selecionar todos</b-button>
                <b-button size="sm" @click="clearSelected">Limpar seleção</b-button>
            </p>
            <b-alert v-if="items.length>=100" show variant="danger">Exibindo até o limite de 100 registros, refine sua busca.</b-alert>
           <b-table
                ref="selectableTable"
                selectable
                :select-mode="selectMode"
                :items="items"
                :fields="fields"
                @row-selected="onRowSelected"
                responsive="sm"
            >
              
            </b-table>
            
            <!-- <p>
                Selected Rows:<br>
                {{ selected }}
            </p> -->
        <!-- <b-button size="sm" @click="bater">Bater</b-button>  -->
        <!-- <b-row> 
            <b-col>
            <b-form-group size="sm">
                <b-form-radio-group
                    id="btn-processos"
                    v-model="procSelect"
                    :options="processos"
                    buttons
                    stacked
                    button-variant="outline-primary"
                    name="radio-btn-outline"
                ></b-form-radio-group>
                <b-collapse id="collapse-1" class="mt-2" :visible="procSelect=='recalcular' ? true : false"  :class="agendaSelect=='agenda' ? null : 'collapsed'">
                    <b-card>
                        <label for="">Duração Limite</label><b-form-input ref="input-limite" v-model="limite" type="number" :state="validaDuracao(limite)"></b-form-input>
                        <label for="">Primeiro Minuto</label><b-form-input v-model="primeiro" type="number" :state="validaDuracao(primeiro)"></b-form-input>
                        <label for="">Fração</label><b-form-input v-model="fracao" type="number" :state="validaDuracao(fracao)"></b-form-input>
                    </b-card>
                </b-collapse>
            </b-form-group>
            </b-col>
            <b-col>
                <b-form-group size="sm">
                    <b-form-radio-group
                        id="btn-agenda"
                        v-model="agendaSelect"
                        :options="agenda"
                        buttons
                        stacked
                        button-variant="outline-primary"
                        name="radio-btn-outline"
                    ></b-form-radio-group>
                    <b-collapse id="collapse-2" class="mt-2" :visible="agendaSelect" :class="agendaSelect ? null : 'collapsed'">
                        <b-card>
                            <p class="card-text">Escolha a data/hora desejada.</p>
                            <b-form-input ref="input-data" type="date" v-model="data" :state="data=='' ? false : true"></b-form-input>
                            <b-form-input ref="input-hora" type="time" v-model="hora" :state="hora=='' ? false : true"></b-form-input>
                        </b-card>
                    </b-collapse>
                 </b-form-group> 
            </b-col>
        </b-row>
        
        <b-row>
            <b-col>
                <b-button  @click="executar">Executar</b-button> 
                <b-button  @click="$bvModal.hide('modal-editar-processo')">Cancelar</b-button>
            </b-col>
            
        </b-row>-->
        
        
        <b-alert v-if="erro" show variant="danger">Falha ao executar. Detalhe do erro {{erroDetalhe}}</b-alert>
        <b-alert v-if="sucesso" show variant="success">Solicitação feita com sucesso.</b-alert>
        </b-container>
    </div>
</template>
as_contrat,as_nome,as_cpf,produto
<script>
export default {
    name:'clientes',
    data(){ 
        return {
            input:'',
            url:'clientes/',
            selectMode:'multi',
            items:[],
            fields: [
                { key: 'AS_CONTRAT',
                label: 'Contrato' },
                { key: 'AS_NOME',
                label: 'Nome' },
                { key: 'AS_CPF',
                label: 'Documento' },
                { key: 'PRODUTO',
                label: 'Produto' },
            ],
            selected:[],
            agendaSelect:false,
            erro:false,
            erroDetalhe:'',
            sucesso:false,
            data:'',
            hora:'',
            limite:0,
            primeiro:0,
            fracao:0,
        }
    },
    props:{
        iniClientes:Array
    },
    methods:{
        onRowSelected(items) {
            this.selected = items
            // console.log('selecionado', items);
        },
        selectAllRows() {
            console.log('selecionado todos');
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
            // Rows are indexed from 0, so the third row is index 2
            this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
            // Rows are indexed from 0, so the third row is index 2
            this.$refs.selectableTable.unselectRow(2)
        },
    },
    watch:{
       input:async function(i) {
        //    console.log('i', i)
            if (i.length>2){
                let r = await this.$http.get(this.url+'?filter='+String(i).toLowerCase()) 
                // console.log('a', r);
                this.items = r.data
            }else{
                this.items = []
            }
        },
        selected:function(c){
            console.log('selecionado', c);
            this.$emit("setCliente", c)
        }
    },
    mounted(){
        if (this.iniClientes.length>0){
            this.items=this.iniClientes
            setTimeout(()=>this.selectAllRows(),100)//parece q se eu nao colocar um delay nao da tempo de selecionar os registros antes da tabela estar montada com eles
        }
    }
}
</script>


<style>

</style>