<template>
  <div>
  <b-form-group>
        <b-form-checkbox-group
            id="checkbox-empresas-fiscais"
            v-model="empFiscaisSelect"
            :options="empFiscaisLista"
            name="flavour-1"
            :disabled="disabled"
            :stacked="stacked"
        />
    </b-form-group>
    </div>
</template>

<script>
export default {
    data(){
        return{
            empFiscaisLista: [],
            empFiscaisSelect:[]
        }
    },
    props:{
        disabled:{
            type:Boolean,
            default: false
        },
        stacked:{
            type:Boolean,
            default:true
        },
        apenasEmpresasSelecionadas:{
            type:Array,
            default(){ return []}
        }
    },
    methods:{        
         async carregaListaEmpresas() {
            try{
                let r = await this.$http.get("/login/meusdados/empresas-fiscais/")
                console.log("empresas", r.data)
                Object.values(r.data).forEach(v => {                    
                    this.empFiscaisLista.push({
                        text: v.NOME_EMP_FISCAL,
                        value: v.ID_EMP_FISCAL
                    });
                    if (this.apenasEmpresasSelecionadas.length==0 || this.apenasEmpresasSelecionadas.includes(v.ID_EMP_FISCAL))
                        this.empFiscaisSelect.push(v.ID_EMP_FISCAL);
                });
            }catch(err){
                console.log('erro carregamento empfiscais', err)
            }
        },
    },
    async mounted(){
        //todo talvez o app monte e faca a request porHora para a api sem antes ter isso aqui montado. na pratica nao tera nenhum problema pois a api porHora fara a consistencia da hierarquia corretamente
        //porem caso isso aconteca, apos esse componente ser montado ele ira alterar a variavel Filtro da App e fara a requisição novamente. Seria um pouco para otimizar.
        console.log('ini mounted empresas', this.apenasEmpresasSelecionadas);
        await this.carregaListaEmpresas()
        console.log('fim mounted empresas');
    },
    watch:{
        empFiscaisSelect(){
            console.log('watch empFiscaisSelect', this.empFiscaisSelect);
            this.$emit("hit",this.empFiscaisSelect)
        }
    }
}
</script>

<style>
/* todo embora esse codigo esteja nesse componente ele acaba alterando para todo o projeto, preciso colocar isso no lugar correto */
/* .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(108, 117, 125)!important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgb(108, 117, 125);
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color:rgb(108, 117, 125); 
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.25);
} */
</style>