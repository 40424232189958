<script>

    import { Line, mixins } from 'vue-chartjs'
    const { reactiveData } = mixins

    export default {
        extends: Line,
        mixins: [reactiveData],
        data(){
            return{
                // chartData:{},
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    title: {
                        display: true,
                        text: this.titulo
                    },
                },
            }
        },
        props: {
            linhas:{
                type: Array,
                default: ()=>[]
            },
            titulo:String
        },
        mounted () {
            console.log('montando linechart titulo', this.titulo)
            this.chartData={
                    labels: [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                    23
                    ],
                    datasets: this.linhas
                },
            this.renderChart(this.chartdata, this.options)
        },
        methods:{
            refreshGrafico() {
                this.$data._chart.update();
            },
        },
        watch:{
            linhas:{
                handler(){
                    // console.log('charts watch linhas',this.linhas);
                    // this.chartData.datasets=this.linhas
                    this.$data._chart.update()
                    // this.renderChart(this.chartdata, this.options)
                },
                // deep: true,
            },
            // titulo(){
            //     this.$data._chart.update()
            //     // this.renderChart(this.chartdata, this.options)
            // }
        }
    }

</script>