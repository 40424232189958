<template>
    <!-- <div>
        <b-row class="h100">
            <b-col cols="1.5" id="menu">
                <div id="menu">
                    <b-nav vertical>
                        <b-nav-brand  align="center" class="navbar-brand .d-flex" to="/minutagem">Minutagem</b-nav-brand >
                        <b-nav-item to="/app">Gráfico Online</b-nav-item>
                        <b-nav-item @click="sair">Sair</b-nav-item>
                    </b-nav>
                </div>
            </b-col>
            <b-col>
                <router-view ></router-view>
            </b-col>
        </b-row>
    </div> -->

    
    <div class="wrapper">
        <nav id="sidebar">
            <div class="sidebar-header">
                <h3>
                    <b-link to="/minutagem" exact>Minutagem</b-link>
                </h3>
            </div>

            <ul class="list-unstyled components">
                <li>
                    <b-nav-item to="/app">Gráfico Online</b-nav-item>
                    <b-nav-item to="/diario">Relatório Diário</b-nav-item>
                    <b-nav-item to="/periodo">Relatório Período</b-nav-item>
                    <b-nav-item to="/zerado">Relatório Clientes Zerados</b-nav-item>
                    <!-- <router-link active-class="active" to="/diario">Relatório Diário</router-link> --> 
                    <!-- todo melhorar o menu para destacar o link ativo -->
                </li>
                 <li>
                    <b-nav-item @click="sair">Sair</b-nav-item>
                </li>
            </ul>
        </nav>

        <div id="content">
            <router-view ></router-view>
        </div>
    </div>


</template>

<script>
export default {

    created(){
        console.log('dash criado', this.$route.query.redirect);
        this.$router.push({ path: this.$route.query.redirect || '/minutagem' })
    },
    methods:{
        async sair(){
            // document.cookie = "sessionidmin= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
            await this.$http.get('login/sair') 
            console.log('logoff com sucesso')
            this.$router.push({ path: '/' })
        }
    }
}
</script>

<style scoped>
/* classes usadas no menu antigo */
.h100{
    height: 100%;
}
#menu{
    /* background-color: #c7c4c47a; */
    /* background: rgba(20, 117, 162, 0.438); */
    
    height: 100%;
    /* color:red; */
    /* height: 5000px; */
}
/* ------------------------------------------------------------------ */

#content{
    height: 100%;
    width: 100%;
}
.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


#sidebar {
    width: 180px;
    /* position: fixed; */
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    /* background: #7386D5; */
    /* background: rgb(34,193,195); */
    /* background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(83,111,238,1) 50%); */
    color: #fff;
    transition: all 0.3s;
}
a[data-toggle="collapse"] {
    position: relative;
}
/* abaixo desse valor o menu desaparece */
/* @media (max-width: 100px) { */
    /* #sidebar {
        margin-left: -180px;
    } */
    /* #sidebar.active {
        margin-left: 0;
    } */
/* } */
/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */


/* body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
} */

/* p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
} */

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
     background: #7386D5;/* cor original do template.  */
   
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 10px;
    background: #6d7fcc;
    align:center;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
    
}

/* #sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
} */
/* ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
} */
</style>