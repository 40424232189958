<template>
    <div>
        <p class="mb-1">Filtros:</p>
        <EmpresasFiscais @hit="setEmpresasFiscais"/>
        <Assessor class="mt-1" id="aa" @hit="setAssessor" label />
        <b-button class="mt-1" variant="primary" @click="showModalClientes">Filtrar contratos</b-button>
        <p v-if="!selectedClientes.length">Todos os contratos estão selecionados!</p>
        <p v-if="selectedClientes.length">Quant. de contratos filtrados: {{selectedClientes.length}}</p>
        <b-modal id="modal-filtro-clientes" size="xl" title="Clientes" hide-footer>
            <clientes @setCliente="setCliente" :iniClientes="selectedClientes" />
                <template v-slot:modal-header="{ close }">
                    <b-button size="sm" @click="close()">
                        Voltar
                    </b-button>
                </template>
        </b-modal>
    </div>
</template>

<script>
import Assessor from "./Assessor"
import clientes from "./Clientes"
import EmpresasFiscais from "./EmpresasFiscaisCheck"
export default {
    components:{Assessor, clientes, EmpresasFiscais},
    data(){
        return {
                selectedEmp: [],
                cargo:'',
                codvend: 0,
                selectedClientes: [],
                refresh:0 //pensar melhor jeito. aqui vou somando um numero apenas acionar o watcher do filtro do grafico forçando ele a consultar o banco novamente
        }
    },
    methods:{
        setCliente(c) {
            console.log("cliente selecionado app", c);
            this.selectedClientes = c;
            this.$emit("setFiltro",this.getUrlFiltro())
        },
        setAssessor(s) {
            this.codvend = s ? s.COD_VEND : null
            this.cargo = s ? s.cargoSelect : null
            console.log("setAssessor", s)
            this.$emit("setFiltro",this.getUrlFiltro())
        },
        setEmpresasFiscais(emps){
            console.log('emps1', emps)
            this.selectedEmp=emps
            this.$emit("setFiltro",this.getUrlFiltro())
        },
        showModalClientes() {
            console.log("clique showModalClientes")
            this.$bvModal.show("modal-filtro-clientes")
        },
        getUrlFiltro(){
            let contratos = [];
            let url = "&empresas=" + this.selectedEmp.toString()
            url += this.codvend ? "&" + this.cargo + "=" + this.codvend : ""
            Object.values(this.selectedClientes).forEach(c => contratos.push(c.AS_CONTRAT))
            url += contratos.length > 0 ? "&contratos=" + contratos.toString() : ""
            return url
        },
    },
    /*wacth:{
        filtro:{
            handler(){
                console.log('watch filtro', this.filtro)
            },
            deep:true
        }
    }*/

}
</script>

<style>

</style>